import React from 'react'
import Fade from 'react-awesome-reveal'
import {
  FaFacebookSquare,
  FaEnvelope,
  FaInstagramSquare,
  FaLinkedin,
  FaShieldAlt
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='w-full border-t-2 border-[#e37200] mx-auto px-4 grid lg:grid-cols-3 gap-8 text-gray-300 bg-[#05305e]'>
      <div>
        <div className='flex text-[#e37200] justify-between md:w-[75%] my-6 order-1'>
          <Fade cascade damping={0.2}>
            <Link to='https://www.linkedin.com/company/cleanmetroinc/'><FaLinkedin size={30} /></Link> 
            <Link to='https://www.facebook.com/CleanMetroInc/'><FaFacebookSquare  size={30}/></Link>
            <Link to='https://www.instagram.com/cleanmetroinc/'><FaInstagramSquare  size={30}/></Link>
            <a href='mailto:service@cleanmetro.com'><FaEnvelope size={30} /></a> {/* Add email icon with mailto link */}
            <Link to="/Privacy"><FaShieldAlt  size={30}/></Link>
          </Fade>
        </div>
      </div>
      <div className="lg:col-span-2 lg:flex lg:justify-end items-center">
        <Fade>
        {/* <p className="lg:text-right lg:w-full">
          803-575-0203
        </p> */}
        </Fade>
      </div>
    </div>
  )
}

export default Footer
