import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Backdrop from '../Assest/Banners/Backdropnew.jpg';

const Privacy = () => {
    return (
        <div className='content-center text-center font-link border-white shadow-lg rounded-lg text-black bg-white'>
            <div className='bg-[#05305e] relative z-10'>
                <Navbar />
            </div>
            <Fade>
                <div className='text-center mx-auto grid gap-6'>
                    <div className='p-[6%] lg:p-[4%] my-3'>
                        <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Clean Metro Inc. Privacy Policy</h1>
                        <div className='text-2xl'>
                            <h2>Stay in Control of the Ads You See</h2>
                            <p>Clean Metro Inc. believes in giving you the power to stay in control of how we use your information. Clean Metro Inc. believes in giving you the power to stay in control of how we use your information. Therefore, we give you the ability to choose whether to have your data collected from this device for the purpose of receiving Retargeted or other online ads from Clean Metro Inc.</p>
<br></br>
<p>Our commitment to protecting your privacy is outlined in the policy below. This privacy policy describes the overall company Privacy Policy of Clean Metro Inc. Inc. (referred to as "Clean Metro Inc.", "Clean Metro Inc..com", "we", "us", "our") and the services offered on the Clean Metro Inc..com websites (referred to as "Services", "Service").</p>
                            <h3>Our Privacy Policy Explains:</h3>
                            <br></br>
                            <ul className='list-disc list-inside'>
                                <li>What information we collect</li>
                                <li>How we use that information</li>
                                <li>What information we share with our partners</li>
                                <li>The choices we offer</li>
                            </ul>
                            <br></br>
                            <p>Clean Metro Inc.Privacy Policy is broken out into two sections. Section I explains Clean Metro Inc.privacy policies regarding the collection, use, and sharing of data from visitors to our website and the online advertising Clean Metro Inc. displays across various websites. Section II covers Clean Metro Inc.privacy policies with regard to customer accounts.</p>
                            <br></br>
                            <h2>Section I – Clean Metro Inc. Advertising Services</h2>

                            <h3>The Benefits of Interest-Based Advertising:</h3>
                            <br></br>
                            <ul className='list-disc list-inside'>
                                <li><strong>Save Money:</strong> Relevant ads can offer you deals and discounts that will interest you and save you money.
                                </li>
                                <li><strong>Free Your Inbox:</strong> Brands can market to you without clogging your email inbox with messages.</li>
                                <li><strong>Keep Content Free:</strong> Free online content is made possible by effective online advertising, like retargeting.
                                </li>
                            </ul>

                            <h3>What Information Does Clean Metro Inc. Collect and How Do We Use That Information?</h3>
                            <br></br>
                            <h4>Information We Collect and How We Use It</h4>
                            <br></br>
                            <p>Clean Metro Inc. collects data about your activities that does not personally or directly identify you when you visit our website, the website of entities for which we serve advertisements (our “Advertisers”), or the websites and online services where we display advertisements (“Publishers”). This information may include the content you view, the date and time that you view this content, the products you purchase, or your location information associated with your IP address. We use the information we collect to serve you more relevant advertisements (referred to as Retargeting, Pay Per Click Advertising, PPC, Display Advertising, Remarketing, Dynamic Ads, Google Adwords Ads, Similar Audience Ads, Bing Ads, Yahoo!, Demand Side Platform (DSPs), Real Time Bidding Platforms and similar online advertising platforms). We collect information about where you saw the ads we serve you and what ads you clicked on.
 
 We do not target ads to you based on sensitive personal data, such as information related to race or ethnic origin, political opinions, religious beliefs or other beliefs of a similar nature, trade union membership, physical or mental health or condition or sexual life.  Where permitted by law, we do work with health and fitness companies and may show you more relevant ads based on your perceived interest in these topics.
  
 Clean Metro Inc. may target ads based on data provided by Advertisers or partners alone or in combination with the data we collect ourselves. Any data used to serve targeted advertisements is de-identified and is not used to personally or directly identify you. We require third parties, including Publishers, to provide notice and obtain appropriate consent, where required by applicable law.
 </p>

                            <h4>Third Parties on Our Website and Our Advertisers’ Websites In order to allow Clean Metro Inc. and our Advertisers' Websites</h4>
                            <br></br>
                            <p>In order to allow Clean Metro Inc. and our Advertisers to reach the best inventory online, we work with third party advertising companies (our “Advertising Partners”) to help us recognize you and serve relevant advertisements to you when you visit a website or online service in their network. We also work with Advertising Partners who help us recognize you across different devices in order to show you relevant advertisements. Our Advertising Partners may collect information about your activities on our website, our Advertisers’ websites, and other websites or online services in their networks. We also work with third party companies to assist us with website analytics such as evaluating the use and operation of our website so that we can continue to enhance the website and our services.</p>
                            <br></br>
                            <h4>Technologies We and Our Third Party Advertising Partners Use</h4>
                            <br></br>
                            
                            <h4>Technologies We and Our Third Party Advertising Partners Use</h4>
                            <p>Clean Metro Inc. uses technologies such as cookies and pixels to keep track of your activities on our website and our Advertiser’s websites, and to serve you more relevant advertisements. A cookie is a small text file that is stored on your device for record-keeping purposes. Session cookies expire when you close your browser. Persistent cookies remain on your device for an extended period of time. We and our third party partners use session cookies and persistent cookies to make it easier for you to navigate and enhance the experience of our site, and to monitor and evaluate our website’s operation and use.</p>
                            <br></br>
                            
                            <p>We use persistent cookies to keep track of your activities when you visit our website or our Advertisers’ websites, and to serve you relevant advertisements. Our Advertising Partners also place persistent cookies on your device when you visit our website or our Advertisers’ websites in order to help us recognize you and serve relevant advertisements to you when you visit their website or online service or websites in their networks. Clean Metro Inc.policy is that our Advertising Partners only collect information that does not personally or directly identify you via these cookies and we will cease working with partners found violating this policy. You can remove cookies by following directions provided in your Internet browser’s “help” file. You may also decline our cookies if your browser permits, but doing so may interfere with your use of our website.</p>
                            <br></br>
                            <h4>Your Choices Regarding Relevant Advertisements</h4>
                            <br></br>
                            <p>We believe in giving you control over which ads you see. You may opt out of having your data collected from this device for the purpose of receiving Retargeted ads from Clean Metro Inc. (whether served by us or on our behalf). You may also opt out of receiving relevant ads from some of our Advertising Partners. If you delete your cookies you will need to opt out again. Further, if you use multiple browsers or devices, you will need to execute this opt out on each browser or device.
 
 Where required by applicable law, we obtain individuals’ consent for storing and receiving information collected via technologies such as cookies and pixels to keep track of your activities on our website and our Advertiser’s websites, and to serve you more relevant advertisements.
 </p>

                            <h4>How We Share Your Information</h4>
                            <p>We may share your information with:</p>
                           <br></br>
                                <p>Service providers under contract who help with parts of our business operations, such as fraud prevention, bill collection, marketing, and technology services. We may share your information with service providers who assist us with serving relevant ads to you. We require by contract that our service providers only use your information in connection with the services they perform for us and not for their own benefit.
We share data derived from or relating to cookies, tracking pixels and other similar technologies with third parties that use Clean Metro Inc.services so such businesses can target you with ads which they think you might be interested in based on your browsing behavior.
We may share aggregated non-identifiable information with Publishers or our Advertisers, such as the number of users who visit a Publisher website or the number of users who clicked on a particular advertisement. We encourage you to review the privacy policies of the website publishers that you visit to learn about their data collection practices. We do not provide information to our Advertisers about your activities. However, when you view or interact with a Retargeted ad, the Advertiser may infer that you engaged in the activities on its site that qualified you to receive the ad.
</p>
<p>Companies that Clean Metro Inc. merges with or that acquire Clean Metro Inc..
Other third parties with your consent or direction to do so.
We may share information about you if necessary to comply with laws or regulations or in response to a valid subpoena, order, or government request, or to protect the rights of Clean Metro Inc. or others.
</p>
                            
                            <br></br>
                            <h2>Section II – Clean Metro Inc. Customers</h2>
                            <br></br>
                            <h3>Account Information We Collect</h3>
                            <p>If you open an account, we collect the following types of personal information from you:</p>
                            <ul className='list-disc list-inside'>
                                <li>Contact information - your name, address, email, and other similar information.
Financial information - credit card numbers or PayPal account information that you link to your Clean Metro Inc..com account.
Clean Metro Inc..com account login information.
Where allowed by applicable law, we also collect your Social Security Number and/or Tax ID if you sign up to receive referral payments by referring customers to Clean Metro Inc..
</li>
                                <li>We may collect additional information from or about you in other ways not specifically described here. For example, we may collect information related to your contact with our customer support team or store results when you respond to a survey.</li>
                                <li>Some categories of personal information may be marked as mandatory in our online and offline forms. We may not be able to provide you with the product or service requested if you decide not to provide the personal information marked as mandatory.</li>
                            </ul>
                            <p>We do not link the non-personally identifiable information we store using cookies (described above) to any personally identifiable information you submit while on our site.</p>
                            <br></br>

                            <h4>How We Share Personal Information with Other Parties</h4>
                            <br></br>
                            <p>We may share your personal information with:</p>
                            <br></br>
                            <p>Service providers under contract who assist us with our business operations, such as fraud prevention, bill collection, marketing, and technology services. Our service providers are only authorized to use your information in connection with the services they perform for us and not for their own benefit.
Credit bureaus to report outstanding negative balance accounts, as allowed by law.
Companies that Clean Metro Inc. merges with or that acquire Clean Metro Inc.. In this event, we will notify you before your information is transferred and becomes subject to a different privacy policy.
Other third parties with your consent or direction to do so.
We may share information about you if necessary to comply with laws or regulations or in response to a valid subpoena, order, or government request, or to protect the rights of Clean Metro Inc. or others.
</p>
                            <br></br>
                            <h4>How you can access or change your personal information</h4>
                            <br></br>
                            <p>
 
 If you are located in the European Economic Area, you may have the right to request access to and receive information about the personal information we maintain about you, update and correct inaccuracies in your personal information and have the information blocked or deleted, as appropriate. The right to access personal information may be limited in some circumstances by local law requirements. You also have the right to opt out, free of charge, from the processing of your personal information for marketing purposes. All requests for access, rectification, blocking and opting-out must be made in writing, signed, dated and accompanied by a copy of your ID card.
 </p>
                            <h2>Other Site Policies That Apply to Both Section I & II</h2>
                            <br></br>
                            <h3>How We Protect Personal Information</h3>
                            <p>We employ reasonable and appropriate security measures such as maintaining physical, electronic and procedural safeguards in compliance with applicable security requirements, including US federal and state regulations. We use computer safeguards such as firewalls and data encryption; we enforce physical access controls to our buildings and files; and we authorize access to personal information only for those employees who require it to fulfill their job responsibilities. All credit card information is encrypted with 128-bit SSL encryption before it is transmitted across the network. However, no security system is foolproof and we cannot guarantee the security of your information.</p>
                            <br></br>
                            <h4>Data Transfers</h4>
                            <br>
                            </br>
                            <p> 
If you are located in the European Economic Area, or other regions of the world with laws governing data collection and use, we comply with applicable legal requirements providing adequate protection for the transfer of personal information. We may transfer the personal information we collect about you to countries other than the country in which the information was originally collected for the purposes of storage and processing of data, fulfilling your requests, and operating the Service. Those countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your information to other countries, we will protect that information as described in this Privacy Policy.
 
By providing any information, including personal information, on or to the Service, you consent to such transfer, storage, and processing.
 
</p>
<br></br>
                            <h3>Unsupervised Children</h3>
                            <p>Children 18 and under are not eligible to use Clean Metro Inc. services without participation and supervision of a legal parent or guardian. Clean Metro Inc. is not a site meant for children, and we do not provide privacy measures designed to protect children when using the Internet unsupervised. Clean Metro Inc. also does not knowingly target children under the age of 13 and makes reasonable efforts to ensure that this does not occur across any campaigns it runs on behalf of advertisers or for its own marketing.
 
 </p>
                            <br></br>
                            <p>Other Sites’ Policies
 
 Clean Metro Inc. has no control over, nor does it bear any of the responsibility for the privacy policies and/or content of any sites or advertisers linked to or on Clean Metro Inc.website, or the cookies these sites might set in your browser. Clean Metro Inc. requires that Advertisers that have elected to use Clean Metro Inc. provide a descriptive privacy policy linked to from their website describing their practices regarding Online Behavioral Advertising.
 </p>
                            <h3>How You Can Contact Us About Privacy Questions or Complaints</h3>
                            <br></br>
                            <h4>Specific Privacy Policy Requirements</h4>
                            <br></br>
                            <h4>Google Adwords:</h4>
                            <p>This website uses Google AdWords
 
 This website uses the Google AdWords remarketing service to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven’t completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone’s past visits to the Clean Metro Inc. websites. Of course, any data collected will be used in accordance with our own privacy policy and Google’s privacy policy.
 You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.
 </p>
 <br></br>
 Opting Out:   <Link className=' font-extrabold text-orange-600' to="http://www.google.com/settings/ads">Google's Ads Settings</Link>. Google's Ads Settings. Alternatively, you can point your visitors to opt out of a third-party vendor's use of cookies by visiting the <Link className=' font-extrabold text-orange-600' to="http://www.networkadvertising.org/managing/opt_out.asp">Network Advertising Initiative opt-out</Link> page. 

<h4>AdRoll:</h4>
<br></br>
<p>As you browse Clean Metro Inc.websites advertising cookies will be placed on your computer so that we can understand what you are interested in. Our display advertising partner, AdRoll, then enables us to present you with retargeting advertising on other sites based on your previous interaction with Clean Metro Inc.websites. The techniques our partners employ do not collect personal information such as your name, email address, postal address, or telephone number.  You can visit this page to opt out of AdRoll’s and their partners’ targeted advertising.</p>
                            <br></br>
                            <h4>Opting Out:</h4>
                            <Link className=' font-extrabold text-orange-600' to="http://info.evidon.com/pub_info/573?v=1&nt=1&nw=false">
                            Click here to adjust your advertising preferences
                            </Link>
                            <br>
                            </br>
                            <p>AdRoll is an approved member of the Network Advertising Initiative (NAI). If you prefer, you may use the <Link className=' font-extrabold text-orange-600' to="http://www.networkadvertising.org/choices/">NAI opt out tool</Link>. We also comply with the Self-Regulatory Principles for Online Behavioral Advertising as managed by the Digital Advertising Alliance (DAA). You may opt out via the <Link className=' font-extrabold text-orange-600' to="http://www.aboutads.info/choices/">DAA website</Link>.
We provide opt-out mechanisms through the tools linked above and do not currently respond to browser “Do Not Track” signals.
</p>
<br></br>
<h4>How you can contact us about privacy questions or complaints</h4>
                            <p>If you have any questions about this privacy statement, the practices of this site, or your dealings with this site, you can contact service@cleanmetro.com  or write to us at the address below.
 
 If you wish to make a complaint about our practices related to online behavioral advertising, you can contact service@cleanmetro.com  or write to us at the address below.
  
 If you are located in the European Economic Area, Clean Metro Inc., Inc. is the entity responsible for the processing of your personal information. To exercise your rights of access, rectification, blocking, or deletion or to object to the processing of your personal information, contact service@cleanmetro.com  or write to us at:</p>
                            <p><strong>Clean Metro Inc.</strong><br />
                            Address: 638 Pine Ridge Dr suite d, West Columbia, SC 29172<br />
                            Phone: (803) 575-0203<br />
                            United States</p>
                            <br></br>
                            <p>Terms of Service Privacy Policy
Copyright © Clean Metro Inc..com. All rights reserved.
</p>
                        </div>

                        <div className='p-[4%] lg:p-[2%] flex flex-col items-center'>
                            <div className="aspect-w-16 aspect-h-9">
                                <img className='rounded-md w-full h-auto max-w-3xl lg:mx-[2%] mb-4' src={Backdrop} alt='Backdrop' />
                            </div>
                        </div>

                        <div className='text-center text-2xl text-white rounded-md p-[6%] lg:p-[4%] shadow-lg bg-[#05305e]'>
                            “It’s rare that you come across an exceptional commercial cleaning group like Clean Metro, Inc. (CMI)… <span className='font-semibold'>CMI would be a great asset</span> to any project. – <span className='font-semibold'>Watson H., Project Manager</span>
                        </div>

                        <div className='text-center'>
                            <Link to='/'>
                                <button className='font-link text-white text-lg bg-[#e37200] w-[200px] rounded-md font-bold my-6 py-3 hover:bg-[#05305e]'>
                                    Return to Home
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fade>
            <Footer />
        </div>
    );
};

export default Privacy;
